import React from 'react';
import ReactDOM from "react-dom";
import Footer from './Footer';

document.addEventListener("DOMContentLoaded", () => {
  const ele = document.createElement("div");
  ele.classList.add('footer-div')
  ReactDOM.render(
    <Footer />,
    document.body.appendChild(ele)
  );
});