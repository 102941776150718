import {
  Box,
  Collapse,
  Container,
  createGenerateClassName,
  createTheme,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  StylesProvider,
  ThemeProvider,
  Typography,
  withStyles,
} from "@material-ui/core";
import React, { useState } from "react";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import YouTubeIcon from "@material-ui/icons/YouTube";
import InstagramIcon from "@material-ui/icons/Instagram";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { FOOTER_COLLAPSE_TYPES } from "../../utils/constants";

const generateClassName = createGenerateClassName({
  productionPrefix: "c",
  disableGlobal: true,
  seed: "footer",
});

const theme = createTheme({
  typography: {
    fontFamily: "'Varela Round', sans-serif",
  },
  overrides: {
    MuiFormLabel: {
      root: {
        "&$focused": {
          color: "#333333",
        },
      },

      focused: {
        color: "#333333",
      },
    },
    MuiInput: {
      underline: {
        "&::after": {
          borderBottom: "2px solid #333333",
        },
      },
    },
  },
});

const FooterBox = withStyles(() => ({
  root: {
    backgroundColor: "#F7F8F9",
    [theme.breakpoints.down("sm")]: {
      paddingTop: 0,
    },
  },
}))(Box);

const PrimaryText = withStyles((theme) => ({
  root: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "20px",
    textTransform: "uppercase",
  },
}))(Typography);

const SecondaryText = withStyles((theme) => ({
  root: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: "20px",
    margin: "8px 0",
  },
}))(Typography);

const Hr = withStyles((theme) => ({
  root: {
    width: 40,
    height: 2,
    backgroundColor: "#000000",
    margin: "20px 0",
  },
}))(Divider);

const CopyRight = withStyles((theme) => ({
  root: {
    padding: "24px 0",
  },
}))(Grid);

const SiteMap = withStyles((theme) => ({
  root: {
    paddingBottom: 16,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}))(Grid);

const SiteMapMb = withStyles((theme) => ({
  root: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
}))(Grid);

const CopyRightSocial = withStyles((theme) => ({
  root: {
    display: "flex",
  },
}))(Box);

const CopyRightIcons = withStyles((theme) => ({
  root: {},
}))(Box);

const InfoWrapper = withStyles((theme) => ({
  root: {
    display: "flex",
    marginBottom: 30,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      marginBottom: 0,
      paddingTop: 16,
    },
  },
}))(Box);

const InfoBox = withStyles((theme) => ({
  root: {
    padding: "0 32px",
    position: "relative",
    "&::after": {
      position: "absolute",
      right: 0,
      top: 5,
      content: '""',
      width: 1,
      height: "70%",
      borderRight: "1px solid #BDBDBD",
    },
    "&:first-child": {
      paddingLeft: 0,
    },
    "&:last-child": {
      paddingRight: 0,
      "&::after": {
        borderRight: 0,
      },
    },
    [theme.breakpoints.down("sm")]: {
      padding: "8px 0",
      "&::after": {
        borderRight: 0,
      },
    },
  },
}))(Box);

const Footer = () => {
  const current_user = window.current_user || {};
  const { id } = current_user;
  const [collapseType, setCollapseType] = useState("");

  const onClickCollapse = (type) => {
    if (collapseType === type) {
      setCollapseType("");
    } else {
      setCollapseType(type);
    }
  };

  return (
    <StylesProvider generateClassName={generateClassName}>
      <ThemeProvider theme={theme}>
        <FooterBox>
          <Container>
            <CopyRight container spacing={3} alignItems={"center"}>
              <Grid item xs={12} sm={9}>
                © {new Date().getFullYear()}. All Rights Reserved.
              </Grid>
              <Grid item xs={12} sm={3}>
                <CopyRightSocial>
                  <Typography
                    style={{
                      flex: 1,
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      (window.location.href = "/terms_and_privacy")
                    }
                  >
                    Terms & Privacy
                  </Typography>
                </CopyRightSocial>
              </Grid>
            </CopyRight>
          </Container>
        </FooterBox>
      </ThemeProvider>
    </StylesProvider>
  );
};

export default Footer;
